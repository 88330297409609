/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Helmet } from 'react-helmet';
import { colors } from '../styles/globals';
import DefaultLayout from '../containers/default';
import {
  PatientsIcon,
  ProvidersIcon,
  PayersIcon,
  SolutionIcon,
} from '../components/icons';
import {
  Section,
  SectionItem,
  SectionTitle,
  SectionDescription,
  SectionLi,
} from '../components/section';
import {
  Banner,
  BannerItem,
  BannerTitle,
} from '../components/banner';

import '../styles/index.css';

export default () => (
  <DefaultLayout>
    <Helmet>
      <title>all.health What It Means For You</title>
      <meta
        name="description"
        content="all.health What It Means For You"
      />
    </Helmet>
    <Banner reverse short backgroundColor={colors.blue}>
      <BannerItem left wide centered>
        <BannerTitle color={colors.white} single>
          all.health™ provides
          {' '}
          <br />
          support across the
          {' '}
          <br />
          healthcare ecosystem
        </BannerTitle>
      </BannerItem>
      <BannerItem right isIcon>
        <SolutionIcon />
      </BannerItem>

    </Banner>
    <Section>
      <SectionItem left isIcon backgroundColor={colors.cream}>
        <PatientsIcon />
      </SectionItem>
      <SectionItem right>
        <SectionTitle>
          for patients
        </SectionTitle>
        <SectionDescription isList as="div">
          <ul>
            <SectionLi>Get or stay healthy with a continuous link to your care team</SectionLi>
            <SectionLi>Manage conditions in a way that blends in with your life</SectionLi>
            <SectionLi>Peace of mind that your care team is looking out for you</SectionLi>
          </ul>
        </SectionDescription>
      </SectionItem>
    </Section>
    <Section reverse>
      <SectionItem left>
        <SectionTitle>
          for providers
        </SectionTitle>
        <SectionDescription isList as="div">
          <ul>
            <SectionLi>Integrate proactive remote care into the patient management workflow</SectionLi>
            <SectionLi>Stay continuously connected with patients after they leave the clinic/hospital</SectionLi>
            <SectionLi>Use data-driven suggestions to optimize care and scale clinical workflows</SectionLi>
            <SectionLi>Increase time with patients, and panel size, by reducing admin load</SectionLi>
          </ul>
        </SectionDescription>
      </SectionItem>
      <SectionItem right isIcon backgroundColor={colors.cyan}>
        <ProvidersIcon />
      </SectionItem>
    </Section>
    <Section>
      <SectionItem left isIcon backgroundColor={colors.sage}>
        <PayersIcon />
      </SectionItem>
      <SectionItem right>
        <SectionTitle>
          for employers
        </SectionTitle>
        <SectionDescription isList as="div">
          <ul>
            <SectionLi>
              Transform experience of employee preventative health across risk areas,
              improving the health and wellbeing of employees — you'll have happier,
              healthier, more efficient, and loyal staff
            </SectionLi>
            <SectionLi>Reduce direct and indirect healthcare costs driven by employee sickness and absence</SectionLi>
            <SectionLi>Help speed up the recovery of staff when sick</SectionLi>
            <SectionLi>Enable strategic decisions around investment in human capital</SectionLi>
          </ul>
        </SectionDescription>
      </SectionItem>
    </Section>
  </DefaultLayout>
);
